import React from "react";

//styles
import { Dropdown } from "./DropdownMenu.styles";

//router
import { NavLink } from "react-router-dom";

const DropdownMenu = ({ handleMenu }) => {
  return (
    <Dropdown>
      {["Photos", "Videos"].map((item, i) => (
        <NavLink
          key={i}
          to={`galary/${item.toLowerCase()}`}
          onClick={handleMenu}
          className={({ isActive }) =>
            isActive ? "nav__active-link" : "nav__inactive-link"
          }
        >
          {item}
        </NavLink>
      ))}
    </Dropdown>
  );
};

export default DropdownMenu;
