import styled from "styled-components";

export const Button = styled.button`
  width: 150px;
  height: 48px;
  background-color: var(--theme-color);
  border-radius: 10px;
  color: white;
  transition: 250ms transform ease-in;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: clamp(14px, 2vw, 1.1rem);
  cursor: pointer;
  border: none;

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    border: 2px solid white;
  }

  @media screen and (max-width: 650px) {
    width: 120px;
    height: 40px;
  }
`;

export const SmallButton = styled.button`
  padding: 8px 18px;
  font-size: clamp(13px, 1.5vw, 15px);
  border-radius: 7px;
  margin-top: 15px;
  color: white;
  background-color: var(--theme-color);
  transition: 250ms transform ease-in-out, 300ms box-shadow ease-in-out;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  cursor: pointer;
  border: none;
  white-space: nowrap;

  &:hover {
    transform: scale(1.04);
  }

  &:active {
    box-shadow: inset -15px 0px 20px 5px rgb(200, 20, 24);
  }

  @media screen and (max-width: 650px) {
    padding: 7px 16px;
  }
`;

export const OutlineButton = styled.button`
  width: 140px;
  height: 38px;
  font-size: clamp(12px, 2vw, 15px);
  border-radius: 7px;
  margin-top: 15px;
  color: var(--theme-color);
  background-color: white;
  transition: 250ms transform ease-in;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid var(--theme-color);
  transition: 350ms background-color ease-in-out, 300ms color ease-in-out;

  &:hover {
    background-color: var(--theme-color);
    color: white;
  }

  @media screen and (max-width: 650px) {
    width: 100px;
    height: 30px;
  }
`;

export const TitleBox = styled.h1`
  font-size: clamp(2rem, 5vw, 3.5rem);
  span {
    color: var(--theme-color);
  }
  @media screen and (max-width: 400px) {
    font-size: clamp(1.4rem, 7.5vw, 2rem);
  }
`;

export const StyledLogo = styled.div`
  flex-basis: 50%;
  font-size: clamp(1.3rem, 2.5vw, 1.4rem);
  font-weight: 600;

  a {
    color: ${({ scroll }) => (scroll === "scroll" ? "black" : "white")};
    display: flex;
    align-items: center;
  }

  img {
    width: 35px;
    height: 35px;
    margin-right: 15px;
  }

  span {
    color: var(--theme-color);
  }

  @media screen and (max-width: 600px) {
    font-size: clamp(1rem, 5.5vw, 1.25rem);
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }
`;
