import React, { useEffect, useState } from "react";

//icons
import { GoLocation } from "react-icons/go";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";

//styles
import { Container } from "./ContactDetails.styles";

//backend
import { client } from "../../../client";

const ContactDetails = () => {
  const [contactData, setContactData] = useState({});

  useEffect(() => {
    const query = `*[_type=="contact"]{
      email,
      phone_no,
      address
    }`;

    client
      .fetch(query)
      .then((data) => setContactData(data[0]))
      .catch((err) => console.log(err));
  }, []);

  const { email, phone_no, address } = contactData;

  return (
    <Container>
      <div className="contact__info">
        <div className="contact__icon">
          <BsTelephone />
        </div>
        <div className="contact__item contact__item--numbers">
          {phone_no?.map((num, i) => (
            <span key={i}>
              <a href={`tel:+91 ${num}`}>{num}</a>
              <span className="contact__num-comma">,</span>
            </span>
          ))}
        </div>
      </div>
      <div className="contact__info">
        <div className="contact__icon">
          <AiOutlineMail />
        </div>
        <div className="contact__item">
          <a href={`mailto:${email}`}>{email}</a>
        </div>
      </div>
      <div className="contact__info">
        <div className="contact__icon">
          <GoLocation />
        </div>
        <div className="contact__item contact__item--address">{address}</div>
      </div>
    </Container>
  );
};

export default ContactDetails;
