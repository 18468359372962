import styled from "styled-components";

export const Dropdown = styled.div`
  width: 135px;
  height: 85px;
  position: absolute;
  top: 45px;
  right: -35px;
  background-color: white;
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 2.5px 1px 8px 1px rgba(0, 0, 0, 0.2);
  transition: 300ms all ease-in-out;

  .nav__inactive-link {
    font-weight: 500;
    font-size: 1.06rem;
    user-select: none;
    transition: 200ms color ease-in;
    color: black;
    cursor: pointer;
    &:hover {
      color: rgb(255, 80, 50);
    }
  }

  .nav__active-link {
    color: rgb(255, 80, 50);
    font-weight: 600;
    font-size: 1.1rem;
  }
`;
