import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const StyledNav = styled.nav`
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  transition: 250ms background-color ease-in-out;
  color: ${({ scroll }) => (scroll === "scroll" ? "black" : "white")};
  background-color: ${({ scroll }) => scroll === "scroll" && "white"};
  box-shadow: ${({ scroll }) =>
    scroll === "scroll" && "0px 5px 8px 0px rgba(0, 0, 0, 0.1)"};

  .nav__menu {
    cursor: pointer;
    svg {
      user-select: none;
      cursor: pointer;
      font-size: clamp(1.45rem, 3vw, 1.7rem);
    }
    @media screen and (min-width: 950px) {
      display: none;
    }
  }

  @media screen and (min-width: 601px) and (max-width: 950px) {
    padding: 0px 40px;
  }
  @media screen and (max-width: 600px) {
    padding: 0px 15px;
  }
`;

export const NavItemsBox = styled.ul`
  min-width: 500px;

  .nav__logo {
    display: none;
  }

  .nav__items {
    flex: 1;
    display: flex;
    justify-content: space-between;

    li {
      list-style: none;
    }

    .nav__item-galary {
      display: none;
    }
  }

  @media screen and (max-width: 950px) {
    min-width: 0;
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 100vh;

    transition: 250ms visibility ease-in-out, 250ms background-color ease-in-out;

    visibility: ${({ menu }) => (menu === "view" ? "normal" : "hidden")};

    background-color: ${({ menu }) =>
      menu === "view" ? "rgba(0, 0, 0, 0.8)" : "rgba(0, 0, 0, 0)"};

    .nav__items {
      width: 60%;
      height: 100vh;
      flex-direction: column;
      justify-content: flex-start;
      background-color: white;
      position: absolute;
      top: 0px;
      transition: 400ms left ease-out;
      left: ${({ menu }) => (menu === "view" ? "0px" : "-60%")};
      padding-left: 40px;

      .nav__logo {
        flex-basis: 7%;
        display: flex;
        align-items: center;
        margin-top: 26px;
        a {
          color: black;
        }
      }

      li,
      .nav__item-galary {
        width: 90%;
        height: 30px;
        margin-top: 10px;
      }

      .nav__item-galary {
        display: block;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .nav__items {
      width: 70%;
      padding-left: 24px;
    }
  }
`;

export const StyledLink = styled(NavLink)`
  font-weight: 500;
  font-size: 1.06rem;
  user-select: contain;
  transition: 200ms color ease-in;
  color: ${({ scroll }) => (scroll === "scroll" ? "black" : "white")};
  cursor: pointer;
  user-select: none;

  &:hover {
    color: var(--secondary-theme-color);
  }

  &.active {
    font-weight: 600;
    color: var(--secondary-theme-color);
    font-size: 1.1rem;
  }

  @media screen and (max-width: 950px) {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    color: black;

    &:hover {
      color: var(--theme-color);
    }

    &.active {
      color: var(--theme-color);
    }
  }
`;

export const DropdownContainer = styled.div`
  position: relative;
  font-weight: 500;
  font-size: 1.06rem;

  .nav__dropdown-menu {
    transition: 200ms color ease-in;
    cursor: pointer;
    &:hover {
      color: rgb(255, 80, 50);
    }
  }

  @media screen and (max-width: 950px) {
    display: none;
  }
`;
