import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  form {
    padding: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(4);
    grid-gap: 20px;

    .textarea {
      grid-column: 1/-1;
    }
    .btn {
      grid-column: 1/-1;
      margin: 0px auto;
      margin-top: 15px;
    }

    @media screen and (max-width: 650px) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(6);
      .textarea {
        grid-column: 1/0;
      }
      .btn {
        grid-column: 0/0;
      }
    }
  }

  @media screen and (max-width: 650px) {
    form {
      padding: 50px 25px;
    }
  }
`;

export const StyledInput = styled.div`
  input,
  textarea {
    border-radius: 5px;
    user-select: none;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-family: "Montserrat", sans-serif;
    font-size: clamp(14px, 2vw, 15px);
    font-weight: 500;
    transition: 250ms all ease-in-out;

    &:focus {
      box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.3);
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  input {
    width: 100%;
    height: 40px;
    text-indent: 15px;
  }

  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  textarea {
    width: 100%;
    height: 250px;
    padding: 15px;
    resize: none;
  }

  .form__input-error {
    font-size: clamp(13px, 1.5vw, 14px);
    color: red;
    margin-top: 5px;
  }
`;
