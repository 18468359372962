import React from "react";

//toast
import { toast } from "react-toastify";

//email
import emailjs from "@emailjs/browser";

//styles
import { Container, StyledInput } from "./ContactForm.styles";
import { Button } from "../../../styles/Main.styles";

//form hook
import { useForm } from "react-hook-form";

const ContactForm = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const sendEmail = (data) => {
    emailjs.send("gmail", "events_site", data, "pzsk5nocssDiDWqKj").then(
      (res) => {
        reset();
        toast.success("SUCCESS!");
      },
      (err) => {
        reset();
        toast.error("FAILED...", err);
      }
    );
  };

  const errorStyle = {
    border: "1px solid red",
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(sendEmail)}>
        <StyledInput>
          <input
            style={errors.name && errorStyle}
            type="text"
            {...register(`name`, { required: true })}
            placeholder="Name"
          />
          {errors.name && (
            <div className="form__input-error">This field is required</div>
          )}
        </StyledInput>

        <StyledInput>
          <input
            style={errors.phone_num && errorStyle}
            type="number"
            {...register(`phone_num`, { required: true })}
            placeholder="Phone No."
          />
          {errors.phone_num && (
            <div className="form__input-error">This field is required</div>
          )}
        </StyledInput>

        <StyledInput>
          <input
            style={errors.email && errorStyle}
            type="email"
            {...register(`email`, { required: true })}
            placeholder="Email"
          />
          {errors.email && (
            <div className="form__input-error">This field is required</div>
          )}
        </StyledInput>

        <StyledInput>
          <input
            style={errors.subject && errorStyle}
            type="text"
            {...register(`subject`, { required: true })}
            placeholder="Subject"
          />
          {errors.subject && (
            <div className="form__input-error">This field is required</div>
          )}
        </StyledInput>

        <StyledInput className="textarea">
          <textarea
            style={errors.message && errorStyle}
            {...register(`message`, { required: true })}
            placeholder="Message"
          ></textarea>
          {errors.message && (
            <div className="form__input-error">This field is required</div>
          )}
        </StyledInput>

        <Button className="btn">Submit</Button>
      </form>
    </Container>
  );
};

export default ContactForm;
