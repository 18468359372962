import React from "react";

//lazy-load
import { LazyLoadImage } from "react-lazy-load-image-component";

//styles
import { Container, LeftBox, Box, RightBox } from "./Details.styles";
import { SmallButton } from "../../styles/Main.styles";

//backend
import { urlFor } from "../../client";

import { useNavigate } from "react-router-dom";

const Details = ({ title, data, selected, handleSelect }) => {
  const navigate = useNavigate();

  //navigating to contact page
  const clickHandler = () => {
    navigate("/contact");
    window.scroll(0, 0);
  };

  return (
    <div>
      <Container>
        <LeftBox>
          <div className="event__heading">
            <span>{title.slice(0, 1)}</span>
            {title.slice(1, title.length)}
          </div>
          <Box>
            {data.map(({ title, _id }) => (
              <div
                key={_id}
                className={`event__type ${
                  selected === title.toLowerCase() && "event__active"
                }`}
                onClick={() => handleSelect(title)}
              >
                {title}
              </div>
            ))}
          </Box>
        </LeftBox>

        {data?.map(
          ({
            title,
            img_url,
            compress_img_url,
            desc_para_one,
            desc_para_two,
            desc_para_three,
            _id,
          }) =>
            title.toLowerCase() === selected && (
              <RightBox key={_id}>
                <div className="event__img">
                  <LazyLoadImage
                    src={urlFor(img_url)}
                    alt={title}
                    effect="blur"
                    placeholderSrc={urlFor(compress_img_url)}
                  />
                </div>
                <div className="event__title">
                  <span>{title?.slice(0, 1).toUpperCase()}</span>
                  {title?.slice(1, title.length)}
                </div>

                <div className="event__desc">
                  <p dangerouslySetInnerHTML={{ __html: desc_para_one }}></p>
                  <p dangerouslySetInnerHTML={{ __html: desc_para_two }}></p>
                  <p dangerouslySetInnerHTML={{ __html: desc_para_three }}></p>
                </div>
                <SmallButton onClick={clickHandler}>Online Request</SmallButton>
              </RightBox>
            )
        )}
      </Container>
    </div>
  );
};

export default Details;
