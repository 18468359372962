import styled from "styled-components";

export const Container = styled.div`
  margin-top: 40px;

  .contact__info {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    .contact__icon {
      width: 35px;
      height: 35px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-right: 10px;
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);

      svg {
        color: var(--theme-color);
      }
    }

    .contact__item {
      font-weight: 500;
      font-size: clamp(15px, 2vw, 1rem);
      cursor: pointer;
      line-height: 1.45rem;

      a {
        transition: 250ms color ease-in-out;
        &:hover {
          color: var(--theme-color);
        }
      }
    }

    .contact__item--numbers {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      span {
        margin-right: 5px;
        font-weight: 500;
        .contact__num-comma {
          font-weight: 500;
          margin-left: 1px;
        }
      }

      span:last-child {
        .contact__num-comma {
          display: none;
        }
      }
    }

    .contact__item--address {
      cursor: auto;
    }
  }
`;
