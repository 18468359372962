import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 1));
  color: white;
  padding: 50px 50px 25px;

  @media screen and (max-width: 600px) {
    padding: 50px 15px 25px;
  }
`;

export const StyledFooter = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 20px 0 15px;

  @media screen and (max-width: 850px) {
    flex-direction: column;
  }
`;

export const BoxOne = styled.div`
  flex-basis: 20%;
  .footer__links {
    margin-top: 30px;
    li {
      width: fit-content;
      list-style: none;
      margin-bottom: 20px;
      color: white;
      font-size: clamp(13.5px, 1.5vw, 1rem);
      font-weight: 500;
      transition: 300ms color ease-in-out;
      cursor: pointer;
      &:hover {
        color: var(--secondary-theme-color);
      }
    }
  }
`;

export const BoxTwo = styled.div`
  flex-basis: 40%;

  .footer__contact-tittle {
    font-size: clamp(1.3rem, 2.5vw, 1.4rem);
    font-weight: 500;
    span {
      color: var(--theme-color);
    }
  }

  .footer__contact-details {
    margin-top: 35px;
  }

  @media screen and (max-width: 850px) {
    margin: 50px 0px;
  }
`;

export const ContactInfo = styled.div`
  display: flex;
  margin-bottom: 25px;

  .footer__contact-icon {
    display: flex;
    align-items: center;
    svg {
      color: var(--theme-color);
      font-size: clamp(15px, 2vw, 1.4rem);
      margin-right: 15px;
      display: block;
    }
  }

  .footer__contact-numbers {
    display: flex;
    flex-wrap: wrap;

    span {
      margin-right: 5px;
      line-height: 1.45rem;
      .footer__contact-comma {
        font-weight: 500;
        margin-left: 1px;
      }
    }

    span:last-child {
      .footer__contact-comma {
        display: none;
      }
    }
  }

  .footer__contact-link,
  .footer__contact-address {
    color: white;
    font-weight: 500;
    font-size: clamp(13.5px, 1.5vw, 1rem);
  }

  .footer__contact-link {
    transition: 250ms color ease-in-out;
    &:hover {
      color: rgb(255, 80, 50);
    }
  }

  .footer__contact-address {
    line-height: 1.45rem;
  }
`;

//social media

export const Media = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-left: 30px;
  color: white;
  box-shadow: 0px 0px 2px 0.8px white;
  border-radius: 50%;
  cursor: pointer;
  transition: 300ms background-color ease-in-out, 300ms box-shadow ease-in-out;

  svg {
    font-size: clamp(15px, 1.5vw, 1rem);
  }

  &:hover {
    background-color: var(--theme-color);
    box-shadow: 0px 0px 1px 0.5px white;
  }

  @media screen and (max-width: 600px) {
    width: 31px;
    height: 31px;
  }
`;

//map
// export const BoxThree = styled.div`
//   border: 1px solid #fff;
//   flex-basis: 35%;
//   height: 300px;
//   @media screen and (min-width: 601px) and (max-width: 1050px) {
//     width: 400px;
//     max-height: 300px;
//   }
//   @media screen and (max-width: 600px) {
//     width: 100%;
//     height: 500px;
//   }
// `;

//copyright
export const CopyrightBox = styled.div`
  text-align: center;
  margin-top: 20px;
  color: rgba(255, 255, 255, 0.9);
  font-size: clamp(12.5px, 1.5vw, 15px);
  line-height: 1.3rem;
  font-weight: 500;
  @media screen and (max-width: 600px) {
    line-height: 1.15rem;
  }
`;
