import styled from "styled-components";

export const ScrollTopButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  font-size: clamp(1.5rem, 2vw, 1.8rem);
  position: fixed;
  z-index: 5;
  right: 30px;
  color: white;
  background-color: var(--theme-color);
  transition: 250ms background-color ease-in,
    400ms bottom cubic-bezier(0.48, 0.34, 0.43, 1.37);
  &:hover {
    background-color: var(--secondary-theme-color);
  }
  &:active {
    background-color: #b60428;
  }
  @media only screen and (max-width: 600px) {
    width: 35px;
    height: 35px;
    right: 20px;
  }
`;
