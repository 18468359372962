import React, { useEffect, useState } from "react";

//icon
import { HiMenuAlt3 } from "react-icons/hi";

//styles
import {
  StyledNav,
  NavItemsBox,
  StyledLink,
  DropdownContainer,
} from "./Navbar.styles";
import { StyledLogo } from "../../styles/Main.styles";

//custom-hook
import useClickOutside from "../../custom-hooks/ClickOutsideHook";

//component
import { DropdownMenu } from "../../components";

//router
import { Link } from "react-router-dom";

//backend
import { client, urlFor } from "../../client";

const Navbar = () => {
  const [logo, setLogo] = useState(null);
  const [isScroll, setIsScroll] = useState("unScroll");
  const [toggleNavbar, setToggleNavbar] = useState(false);
  const [toggledropdownMenu, setToggledropdownMenu] = useState(false);

  useEffect(() => {
    const query = `*[_type=="logo"]{
      logo_url
    }`;
    client
      .fetch(query)
      .then((data) => setLogo(data[0]))
      .catch((err) => console.log(err));
  }, []);

  //custom-hook for closing the navbar slider in small screen
  const navRef = useClickOutside(() => {
    setToggleNavbar(false);
  });

  //custom-hook for closing the dropdown menu in large screen
  const menuRef = useClickOutside(() => {
    setToggledropdownMenu(false);
  });

  //for making the navbar visible on scroll
  useEffect(() => {
    const scrollHandler = () => {
      if (window.scrollY > 40) {
        setIsScroll("scroll");
      } else {
        setIsScroll("unScroll");
      }
    };

    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  //for hiding the navbar on click in small screen
  const toggleNavHandler = () => {
    setToggleNavbar(false);
    window.scroll(0, 0);
  };

  //toggleing dropdown menu
  const menuHandler = () => {
    setToggledropdownMenu((prev) => !prev);
    window.scroll(0, 0);
  };

  const navItems = ["Home", "Events", "Services", "About"];

  return (
    <StyledNav scroll={isScroll}>
      <StyledLogo scroll={isScroll}>
        <Link to={"/"} onClick={toggleNavHandler}>
          {logo && <img src={urlFor(logo.logo_url).url()} alt="logo" />}
          <div>
            <span>destiny</span>
            eveplanner
          </div>
        </Link>
      </StyledLogo>
      <div
        className="nav__menu"
        onClick={() => setToggleNavbar((prev) => !prev)}
      >
        <HiMenuAlt3 />
      </div>
      <NavItemsBox menu={toggleNavbar && "view"} scroll={isScroll}>
        <ul className="nav__items" ref={navRef}>
          <StyledLogo className="nav__logo">
            <Link to={"/"} onClick={toggleNavHandler}>
              <span>destiny</span>eveplanner
            </Link>
          </StyledLogo>

          {navItems.map((item, i) => (
            <li key={i} onClick={toggleNavHandler}>
              <StyledLink
                to={`${item !== "Home" ? "/" + item.toLowerCase() : "/"}`}
                scroll={isScroll}
              >
                {item}
              </StyledLink>
            </li>
          ))}

          <li onClick={toggleNavHandler} className="nav__item-galary">
            <StyledLink to={`/galary/photos`} scroll={isScroll}>
              Photos
            </StyledLink>
          </li>
          <li onClick={toggleNavHandler} className="nav__item-galary">
            <StyledLink to={`/galary/videos`} scroll={isScroll}>
              Videos
            </StyledLink>
          </li>

          <DropdownContainer ref={menuRef}>
            <div
              className="nav__dropdown-menu"
              onClick={() => setToggledropdownMenu((prev) => !prev)}
            >
              Galary
            </div>
            {toggledropdownMenu && <DropdownMenu handleMenu={menuHandler} />}
          </DropdownContainer>

          <li onClick={toggleNavHandler}>
            <StyledLink to={`/contact`} scroll={isScroll}>
              Contact
            </StyledLink>
          </li>
        </ul>
      </NavItemsBox>
    </StyledNav>
  );
};

export default Navbar;
