import React, { useEffect, useState } from "react";

//icons
import { ImWhatsapp } from "react-icons/im";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineInstagram } from "react-icons/ai";
import { IoCall } from "react-icons/io5";
import { MdEmail, MdLocationPin } from "react-icons/md";

//styles
import {
  Container,
  StyledFooter,
  BoxOne,
  BoxTwo,
  ContactInfo,
  CopyrightBox,
  Media,
} from "./Footer.styles";
import { StyledLogo } from "../../styles/Main.styles";

//router
import { Link, useNavigate } from "react-router-dom";

//backend
import { client } from "../../client";

const Footer = () => {
  const navigate = useNavigate();

  const [contactData, setContactData] = useState(null);
  useEffect(() => {
    const query = `*[_type=="contact"]{
      email,
      phone_no,
      address
    }`;

    client.fetch(query).then((data) => setContactData(data[0]));
  }, []);

  const { email, phone_no, address } = contactData !== null && contactData;

  const navItems = [
    "Home",
    "Events",
    "Services",
    "About",
    "Photos",
    "Videos",
    "Contact",
  ];

  //routing the links
  const clickHandler = (item) => {
    const page = item.toLowerCase();
    if (page === "home") {
      navigate(`/`);
    } else if (page === "photos" || page === "videos") {
      navigate(`galary/${page}`);
    } else {
      navigate(`/${page}`);
    }
    window.scroll(0, 0);
  };

  return (
    <Container>
      <StyledFooter>
        <BoxOne>
          <StyledLogo onClick={() => window.scroll(0, 0)}>
            <Link to={"/"}>
              <span>destiny</span>eveplanner
            </Link>
          </StyledLogo>

          <ul className="footer__links">
            {navItems.map((item, i) => (
              <li key={i} onClick={() => clickHandler(item)}>
                {item}
              </li>
            ))}
          </ul>
        </BoxOne>
        <BoxTwo>
          <div className="footer__contact-tittle">
            <span>Connect</span> with us
          </div>
          <div className="footer__contact-details">
            <ContactInfo>
              <div className="footer__contact-icon">
                <IoCall />
              </div>
              <div className="footer__contact-numbers">
                {phone_no?.map((num, i) => (
                  <span key={i}>
                    <a href={`tel:+91 ${num}`} className="footer__contact-link">
                      {num}
                    </a>
                    <span className="footer__contact-comma">,</span>
                  </span>
                ))}
              </div>
            </ContactInfo>

            <ContactInfo>
              <div className="footer__contact-icon">
                <MdEmail />
              </div>

              <a
                href="mailto:destinyeveplanner@gmail.com"
                className="footer__contact-link"
              >
                {email}
              </a>
            </ContactInfo>

            <ContactInfo>
              <div className="footer__contact-icon">
                <MdLocationPin />
              </div>

              <div className="footer__contact-address">{address}</div>
            </ContactInfo>
            <ContactInfo>
              <Media
                href="https://wa.me/+919101502334"
                target="_blank"
                rel="noreferrer"
              >
                <ImWhatsapp />
              </Media>

              <Media
                href="https://www.facebook.com/profile.php?id=100087611405947"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookF />
              </Media>

              <Media
                href="https://www.instagram.com/m.s_decorator/?igshid=YmMyMTA2M2Y%3D"
                target="_blank"
                rel="noreferrer"
              >
                <AiOutlineInstagram />
              </Media>
            </ContactInfo>
          </div>
        </BoxTwo>
      </StyledFooter>
      <CopyrightBox>
        Copyright &copy; {new Date().getFullYear()} Kiran Debnath - All Rights
        Reserved.
      </CopyrightBox>
    </Container>
  );
};

export default Footer;
