import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  .paginate {
    width: 100%;
    display: flex;
    justify-content: center;
    transition: 300ms all ease-in-out;

    li {
      list-style: none;
      a {
        margin: 0px 10px;
        width: 37px;
        height: 35px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.1);
        cursor: pointer;
        transition: 300ms background-color ease-in-out, 300ms color ease-in-out;
        font-weight: 500;
        font-size: clamp(10px, 2vw, 1rem);
        &:hover {
          background-color: var(--theme-color);
          color: white;
        }
        @media screen and (max-width: 600px) {
          width: 21px;
          height: 22px;
          margin: 0px 5px;
        }
      }
    }

    .paginate_active {
      a {
        background-color: var(--theme-color);
        color: white;
      }
    }

    .paginate_previous {
      display: none;
    }

    .paginate_next {
      display: none;
    }
  }
`;
