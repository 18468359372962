import React from "react";

//styles
import styled from "styled-components";
import { OutlineButton } from "../../../styles/Main.styles";

const GalaryCategories = ({ handleCategory, selected }) => {
  const categories = [
    "All",
    "Wedding",
    "Anniversary",
    "Birthday",
    "Rice Ceremony",
    "Festival",
    "Others",
  ];
  return (
    <Container>
      {categories?.map((category, i) => (
        <OutlineButton
          key={i}
          className={`category__btn ${
            selected === category.toLowerCase() && "category__selected-btn"
          }`}
          onClick={() => handleCategory(category)}
        >
          {category}
        </OutlineButton>
      ))}
    </Container>
  );
};

export default GalaryCategories;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .category__btn {
    margin: 10px;
  }

  .category__selected-btn {
    background-color: var(--theme-color);
    color: white;
  }
`;
