import styled from "styled-components";

import { urlFor } from "../../client";

export const Container = styled.div`
  width: 100%;
  height: 620px;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.7)
    ),
    url(${({ img_url }) => img_url && urlFor(img_url)});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  h1 {
    font-size: clamp(2rem, 6.5vw, 4rem);
    letter-spacing: 3px;
    span {
      color: var(--theme-color);
    }
  }

  @media screen and (max-width: 650px) {
    height: 60vh;
  }
`;
