import React, { useEffect, useState } from "react";

//styles
import { Container } from "./Banner.styles";

//backend
import { client } from "../../client";

const Banner = ({ title }) => {
  const [banner, setBanner] = useState(null);

  const firstLetter = title.slice(0, 1);
  const allLetters = title.slice(1, title.length);

  useEffect(() => {
    const query = `*[_type=="othersBanner"]{
      img_url
    }`;

    client
      .fetch(query)
      .then((data) => {
        setBanner(data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Container img_url={banner?.img_url}>
      <h1>
        <span>{firstLetter}</span>
        {allLetters}
      </h1>
    </Container>
  );
};

export default Banner;
