import React from "react";

import { Container } from "./Spinner.styles";

import { InfinitySpin } from "react-loader-spinner";

const Spinner = () => {
  return (
    <Container>
      <InfinitySpin width="220" color="#ff0a3b" />
    </Container>
  );
};

export default Spinner;
