import React from "react";

import { Container } from "./GalaryPagination.styles";

import ReactPaginate from "react-paginate";

const GalaryPagination = ({ handlePageClick, total, perPage }) => {
  return (
    <Container>
      <ReactPaginate
        pageCount={Math.ceil(total / perPage)}
        onPageChange={handlePageClick}
        className="paginate"
        activeClassName="paginate_active"
        previousClassName="paginate_previous"
        nextClassName="paginate_next"
      />
    </Container>
  );
};

export default GalaryPagination;
