import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 100px 50px;

  @media screen and (min-width: 501px) and (max-width: 950px) {
    flex-direction: column;
    margin: 70px 30px;
  }
  @media screen and (max-width: 500px) {
    flex-direction: column;
    margin: 50px 15px;
  }
`;

export const LeftBox = styled.div`
  flex-basis: 25%;
  height: 80vh;
  background-color: var(--primary-background-color);
  padding: 30px 0px 30px 50px;
  border-radius: 10px;
  .event__heading {
    margin-bottom: 30px;
    font-weight: 600;
    font-size: clamp(1.2rem, 3vw, 1.8rem);
    span {
      color: var(--theme-color);
    }
  }

  @media screen and (min-width: 501px) and (max-width: 950px) {
    margin-bottom: 50px;
    padding: 30px 20px;
    .event__heading {
      margin-left: 25px;
    }
  }
  @media screen and (max-width: 500px) {
    margin-bottom: 40px;
    padding: 30px 6px 15px;
    .event__heading {
      margin-left: 15px;
    }
  }
`;

export const Box = styled.div`
  .event__type {
    width: fit-content;
    margin: 20px 0px;
    font-weight: 500;
    font-size: clamp(1rem, 2vw, 1.1rem);
    cursor: pointer;
    transition: 250ms color ease-in-out, 250ms padding-left ease-in-out;

    &:hover {
      color: var(--theme-color);
      padding-left: 10px;
    }

    @media screen and (min-width: 501px) and (max-width: 950px) {
      margin: 0px 25px 15px;
    }
    @media screen and (max-width: 500px) {
      margin: 0px 15px 15px;
    }
  }

  .event__active {
    color: var(--theme-color);
  }

  @media screen and (max-width: 950px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .event__type {
      &:hover {
        padding-left: 0px;
      }
    }
  }
`;

export const RightBox = styled.div`
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .event__img {
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
  .event__title {
    margin: 40px 0px 20px;
    font-weight: 600;
    font-size: clamp(1.3rem, 3vw, 1.8rem);

    span {
      color: var(--theme-color);
    }
    @media screen and (max-width: 500px) {
      margin: 30px 0px 15px;
    }
  }

  .event__desc {
    margin-bottom: 10px;
  }
`;
