import "./App.css";

//carousel css
import "react-alice-carousel/lib/alice-carousel.css";
//lazy-load-image css
import "react-lazy-load-image-component/src/effects/blur.css";

//toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//function for lazy loading
import React, { Suspense, lazy } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { Navbar, Footer } from "./layout";

import { ScrollPage, Spinner } from "./components";

// import ErrorPage from "./pages/ErrorPage/ErrorPage.jsx";

//importing components dynamically
const Home = lazy(() => import(`./pages/Home/Home.jsx`));
const Events = lazy(() => import(`./pages/Events/Events.jsx`));
const Services = lazy(() => import(`./pages/Services/Services.jsx`));
const About = lazy(() => import(`./pages/About/About.jsx`));
const PhotoGalary = lazy(() => import(`./pages/Galary/PhotoGalary.jsx`));
const VideoGalary = lazy(() => import(`./pages/Galary/VideoGalary.jsx`));
const Contact = lazy(() => import(`./pages/Contact/Contact.jsx`));
const ErrorPage = lazy(() => import(`./pages/ErrorPage/ErrorPage.jsx`));

function App() {
  return (
    <div className="app">
      <ToastContainer
        position="top-center"
        autoClose={1500}
        pauseOnHover
        closeOnClick
        theme="light"
      />
      <Router>
        <Suspense fallback={<Spinner />}>
          <ScrollPage />
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="events" element={<Events />} />
            <Route path="services" element={<Services />} />
            <Route path="About" element={<About />} />
            <Route path="galary/photos" element={<PhotoGalary />} />
            <Route path="galary/videos" element={<VideoGalary />} />
            <Route path="contact" element={<Contact />} />
            <Route path="/404" element={<ErrorPage />} />
            <Route path="*" element={<Navigate to={"/404"} replace />} />
          </Routes>
          <Footer />
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
